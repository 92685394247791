import * as packageJson from "./../../package.json";
import { UAParser } from "ua-parser-js";

const url = `${process.env.GATSBY_MORTGAGING_API_BASE_URL}/analytics/event`;

export default class GtmService {
    static instance = GtmService.instance || new GtmService();

    getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    sendAnalyticsEvent(payload) {
        if (navigator.sendBeacon) {
            navigator.sendBeacon(url, new Blob([JSON.stringify(payload)], { type: "application/json" }));
        } else {
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
        }
    }

    /**
     * Use this method to track an event bound to an generic event-object.
     *
     * @param {{ event: string}} _object must not be null, must not be undefined, the object must have at least one
     * event key
     */
    trackEvent(_object) {
        if (window.gtag && _object?.event) {
            const payload = _object.payload || {};
            window.gtag("event", _object.event, payload);

            const referrer = this.getCookie("referrer");

            this.sendAnalyticsEvent({
                action: _object.event,
                params: {
                    ...payload,
                    app: "landing-page",
                    version: `${packageJson.version}-origin`,
                    environment: process.env.APP_ENVIRONMENT,
                    sessionId: this.getCookie("sessionId"),
                    userId: this.getCookie("user_id"),
                    fbclid: this.getCookie("fbclid"),
                    gclid: this.getCookie("gclid"),
                    utm_campaign: this.getCookie("utm_campaign"),
                    utm_content: this.getCookie("utm_content"),
                    utm_medium: this.getCookie("utm_medium"),
                    utm_source: this.getCookie("utm_source"),
                    utm_term: this.getCookie("utm_term"),
                    referrer: referrer ? decodeURIComponent(referrer) : undefined,
                    hostname: window.location.hostname,
                    url: `${window.location.pathname}${window.location.search}`,
                    browser: this.getBrowser(),
                    os: this.getOS(),
                    device: this.getDevice(),
                    systemLanguage: navigator.language,
                },
            });
        }
    }

    getBrowser() {
        const parser = new UAParser();
        const { name, version } = parser.getBrowser();
        return `${name} ${version}`;
    }

    getOS() {
        const parser = new UAParser();
        const { name, version } = parser.getOS();
        return `${name} ${version}`;
    }

    getDevice() {
        const hasTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;

        if (window.innerWidth < 768) {
            return hasTouch ? "phone" : "desktop";
        }

        if (window.innerWidth < 992) {
            return hasTouch ? "tablet" : "desktop";
        }

        return "desktop";
    }
}
